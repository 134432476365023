import React, { Component } from 'react';
import { useParams } from 'react-router-dom';

export default function Article () {
    const { studentId } =  useParams();
    console.log(studentId);
    return (
        <div>StudentId:{studentId }</div>
    );
}

