import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import researchData from '../scholar.json';

function Research() {
  // console.log(typeof(researchData))
  return (
    <Container fluid className="outreach">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">
        Research<strong className="purple"></strong>
        </h1>
        {/* <p style={{ color: "white" }}>
        I teach in Computer Engineering with a focus on Embedded Computer Systems Design; Computer-based Systems in Engineering; Digltal Logic Design; He also teaches graduate courses in Mobile Robotics, and Embedded Sensor Networks.
        </p> */}
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={12} className="project-card">
            <p style={{color: 'white', fontSize: 20}}>I am the Director of the Laboratory for Advanced Sensing, Computation and Control (ASCC Lab) at OSU. The overall research goal of the Laboratory for Advanced Sensing, Computation and Control (ASCC Lab) is to develop innovative algorithms, methodologies, theories as well as prototypes to enable intelligent and robust sensing, decision making and control in various applications.
              You can check out more about my research at <a href='https://ascclab.org' target={'__blank'}>ASCC Lab</a></p>
            {/* <ProjectCard
              // imgPath={chatify}
              isBlog={false}
              // title="ASCC LAB"
              // date={researchData[key].date}
              description="I am the Director of the Laboratory for Advanced Sensing, Computation and Control (ASCC Lab) at OSU. The overall research goal of the Laboratory for Advanced Sensing, Computation and Control (ASCC Lab) is to develop innovative algorithms, methodologies, theories as well as prototypes to enable intelligent and robust sensing, decision making and control in various applications.
              You can check out more about my research at https://ascclab.org"
            /> */}
          </Col>
        </Row>
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Thesis"
              date="6 Jun 2022 - 29 Jul 2022"
              description="A student studying for the master's degree will enroll in this course for a maximum of six credit hours.Offered for variable credit, 1-6 credit hours, maximum of 6 credit hours."
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Computer Based Systems in Engineering"
              date="10 Jan 2022 - 29 Apr 2022"
              description="A comprehensive introduction to technology and applications of microprocessors. Topics include computer hardware, software, programming, computation, interfacing, I/O, communication, data acquisition, data representation, and numerical analysis. Applications of general-purpose and application-specific processors in various disciplines of engineering and engineering problem solving. Previously offered as ENSC 3213.Prerequisite(s): CS 2433 with a 'C' or better."
              ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Dissertation"
              date="10 Jan 2022 - 29 Apr 2022"
              description="Independent research for students continuing graduate study beyond the level of the MS degree.Offered for variable credit, 1-12 credit hours, maximum of 36 credit hours.Prerequisite(s): Consent of major professor."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Computer Based Systems in Engineering"
              date="16 Aug 2021 - 3 Dec 2021"
              description="A comprehensive introduction to technology and applications of microprocessors. Topics include computer hardware, software, programming, computation, interfacing, I/O, communication, data acquisition, data representation, and numerical analysis. Applications of general-purpose and application-specific processors in various disciplines of engineering and engineering problem solving. Previously offered as ENSC 3213.Prerequisite(s): CS 2433 with a 'C' or better."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
      </Container>
    </Container>
  );
}

export default Research;
