import { Container, Row, Col, ListGroupItem } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';

export default function Scholarlship () {
    return (
        <Container fluid className="outreach">
            <Container>
                <h1 className="project-heading">Scholarlship</h1>
                <ul style={{padding: 10, margin: 20, textAlign: 'left', fontSize: 20}}>
                    <li>Best Paper Award of 15th IEEE Workshop on Perception Beyond the Visible Spectrum (PBVS), in conjunction with CVPR 2019 for the paper “Online Reconstruction of Indoor Scenes with Local Manhattan Frame Growing”, by Mahdi Yazdanpour, Guoliang Fan and Weihua Sheng. June 2019.</li>
                    <li>Second Place in the Competition of the 2018 President’s Cup for Creative Interdisciplinarity for the work “Well-being assessment using a Social Robot for Elder Care” PI: Weihua Sheng, Co-PIs: Alex Bishop, Yanmin Gong, Celinda Reese-Melancon, Oklahoma State University, Dec, 2018.</li> 
                    <li>Best Poster Award for the paper “A Driver Assistance Framework based on Driver Drowsiness Detection”,  by Duy Tran, Eyosiyas Tadesse, Weihua Sheng, Yuge Sun, Meiqin Liu and Senlin Zhang. The 6th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (IEEE-CYBER 2016). June 2016.</li>
                    <li>Outstanding Researcher Award in College of Engineering, Architecture and Technology, Oklahoma State University, March, 2015.</li>
                    <li>Best Paper Award for the paper “Classifier fusion for gesture recognition using a Kinect sensor”, by Ye Gu, Qi Cheng and Weihua Sheng.  ISCA 4th International Conference on Sensor Networks and Applications. New Orleans, USA. 2012.</li>
                    <li>Best Conference Paper Award for the paper “Human-Like Indoor Navigation for Autonomous Industrial Mobile Manipulator”, by H. Cheng, H. Chen, Y. Liu and W. Sheng. IEEE International Conference on Information and Automation, 2012.</li> 
                    <li>Best Cotesys Cognition Paper Award for the paper “Using Human Motion Estimation for Human-Robot Cooperative Manipulation”, by A. Thobbi, Ye. Gu and W. Sheng.  IEEE/RSJ International Conference on Intelligent Robots and Systems, 2011.</li>
                    <li>Best Paper in Automation Award for the paper “Human-robot collaborative manipulation through imitation and reinforcement learning”, by A. Thobbi, Ye. Gu and W. Sheng. IEEE International Conference on Information and Automation, 2011. </li>
                    <li>SUPCON Best Paper Award for the paper “Recognizing human daily activity using a single inertial sensor”,  by C. Zhu and W. Sheng, in the 8th World Congress on Intelligent Control and Automation, Jinan, China, 2010.</li>
                    <li>Best Student Paper Award  for the paper ''Wearable Sensors based Human Intention Recognition in Smart Assisted Living Systems'', by C. Zhu, W. Sun and W. Sheng.  IEEE International Conference on Information and Automation, 2008.</li>
                    <li>Best Conference Paper Finalist for the paper “Improving 3D Indoor Mapping with Motion Data”, by Jianhao Du, Yongsheng Ou, and Weihua Sheng.  2012 IEEE International Conference on Robotics and Biomimetics (Robio2012), Dec. 2012</li>
                    <li>Literati Club Highly Commended Paper Award for the paper “CAD-based automated robot trajectory planning for spray painting of free-form surfaces” on the International Journal of Industrial Robot. One of the four best papers in the Year 2002 volume.</li>
                </ul>
            </Container>
        </Container>
    )
}