import { Container, Row, Col } from "react-bootstrap";
export default function Outreach () {
    return (
        <Container fluid className="outreach">
            <Container>
                <h1 className="project-heading">Outreach</h1>
                <ul style={{padding: 10, margin: 20, textAlign: 'left', fontSize: 20}}>
                    <li>Conducted a user study on homecare robots in the local community of Stillwater, OK that involved more than 60 human subjects including older adults, caregivers and family members.</li>
                    <li>Organized the 2022 NSF STEM Robotics Summer Camp for 24 local 8-12 graders from June 22-June 24th 2022.</li> 
                    <li>Organized the robot maze competition and trivia game competition for the students from Stillwater Chinese School. July 1st, 2022.</li>
                    <li>Give a keynote talk in the Workshop on Active Aging Across the Lifespan. May 24, 2022. </li>
                    <li>Participated in the Second Half Expo 2021 in Oklahoma City by demonstrating our ASCC Companion robot to senior citizens around the State of Oklahoma, Oct. 16th, 2021. </li>
                    <li>Organized the Workshop on Robotic Health Assistants for Home Healthcare for local healthcare professionals. Nov. 20th 2020. </li> 
                    <li>Organized the 2019 OSU STEM Robotics Summer Camp for 25 local 8-12 graders, May 28-30th 2019.</li>
                </ul>
            </Container>
        </Container>
    )
}