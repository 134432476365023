import { Container, Row, Col } from "react-bootstrap";

export default function Personal(){
    return (
        <Container fluid className="personal">
            <Container>
                <h1 className="project-heading">Personal</h1>
            </Container>
        </Container>
    )
}