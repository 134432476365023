import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { Button }  from "react-bootstrap"

export default class ArticlePreview extends React.Component {
    render(){
        if (this.props.post) {
          return (
            <Container className="article-main">
                <Container className="article">
                <a href={"/article/" + this.props.post.ID} className="blackLink">
                    {this.props.post.featured_image ? (
                        <img
                        className="img-responsive webpic"
                        alt="article header"
                        src={this.props.post.featured_image}
                        />
                    )
                    :
                    (" ")}
                    <h1 className="text-center">{this.props.post.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: this.props.post.excerpt}} className="content"></div>
                </a>
                </Container>
                <Link to={"/article/" + this.props.post.ID}>
                    <Button variant="dark" className="btn">Read More</Button>
                </Link>
            </Container>
          );
        } else {
          return null;
        }
    }
}