import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import axios from "axios";
import ArticlePreview from "./ArticlePreview";
import Preloader from "./Pre";

export default class Blog extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        posts: []
      };
    }
    componentDidMount() {
      axios
        .get(
          "http://public-api.wordpress.com/rest/v1/sites/ascclab.wordpress.com/posts"
        )
        .then(res => {
          this.setState({ posts: res.data.posts });
        //   console.log(this.state.posts);
        })
        .catch(error => console.log(error));
    }
    
    render() {    
      return (
        <Container fluid className="blog">
            {/* <Preloader load={load} />
            <div className="App" id={load ? "no-scroll" : "scroll"}></div> */}
            {/* <h1 className="sectionTitle">Articles</h1> */}
            <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                {this.state.posts.map(post => 
                <Col md={12} className="project-card">
                    <ArticlePreview post={post} />
                </Col>
                )}
            </Row>
        </Container>
      );
    }
}